import Layout from '@/page/index/'
/**
 * meta参数说明
 * keepAlive是否缓冲页面
 * isTab是否加入到tag导航
 * isAuth是否需要授权
 */

export default [
  {
    path: '/login',
    name: '登录',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/page/login/index'),
    meta: {
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/bindMobile',
    name: '绑定手机号',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/page/login/bindMobile'),
    meta: {
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/components/error-page/404'),
    meta: {
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/upgrade',
    component: () =>
      import(/* webpackChunkName: "page" */ '@/components/error-page/upgrade'),
    name: '系统升级',
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: '/main',
    component: Layout,
    redirect: '/main/index',
    children: [
      {
        path: 'index',
        name: '首页',
        meta: {
          keepAlive: true,
          $keepAlive: true,
          isAuth: true
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/main')
      }
    ]
  }, // 首页
  {
    path: '/test',
    component: Layout,
    children: [
      {
        path: 'index',
        name: '测试',
        meta: {
          keepAlive: false,
          $keepAlive: false,
          isAuth: false,
          isTab: false
        },
        component: () => import(/* webpackChunkName: "views" */ '@/views/test')
      }
    ]
  },
  {
    path: '/',
    name: '主页',
    redirect: '/main'
  },
  {
    path: '*',
    redirect: '/404'
  }
]
